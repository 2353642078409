.ConnectToData {
  box-sizing: border-box;
  height: 99vh;
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  width: 100%;
  gap: 16px;
}

.fieldListCol {
  display: flex;
  flex-direction: column;
  height: 99vh;
}

.fieldList {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0 16px 16px;
  margin: 0;
}

.fieldList li {
  max-width: 100%;
}

.header {
  padding: 24px 30px 16px 16px;
  margin: 0;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
}

.ctaBorderBox {
  --box-top-margin: 26px;
  --box-bottom-margin: 32px;

  margin: var(--box-top-margin) 16px var(--box-bottom-margin) 0;
  border: 1px solid transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 4px;
  height: calc(100% - var(--box-top-margin) - var(--box-bottom-margin));
}

.ctaBorderBox button svg {
  position: relative;
  top: 4px;
}

.cta {
  height: min-content;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.noDataSourceConnected {
  left: 0;
  display: flex;
  align-items: center;
}

.noDataHeader,
.noDataText {
  font-family: 'BentonSans Book', sans-serif;
  font-style: normal;
  font-weight: 350;
  text-align: center;
  letter-spacing: 0.2px;
}

.noDataHeader {
  font-size: 16px;
  line-height: 24px;
  margin: 0;
}

.noDataText {
  font-size: 12px;
  line-height: 18px;
  color: #666;
  margin: 4px 0 0;
}
