.dataSourceDropdown {
  display: block;
  position: absolute;
  background: white;
  z-index: 10;
  right: 20px;
  top: 46px;
  border: 1px solid #e0e0e0;
  padding: 16px 16px 16px 8px;
  box-shadow: 0 4px 16px rgb(0 0 0 / 20%);
  width: 236px;
  max-height: 140px;
  overflow-y: auto;
  overflow-x: hidden;
}

.preHeader {
  font-family: 'BentonSans Light', sans-serif;
  padding-right: 4px;
  font-weight: 300;
  font-size: 14px;
  display: inline-block;
}

.dataSource {
  background: transparent;
  border: none;
  margin-bottom: 6px;
  cursor: pointer;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  max-width: 214px;
  width: 214px;
  position: relative;
  padding: 4px 16px 0 20px;
  color: #666;
}

.dataSource:hover {
  background: #f5f5f5;
}

.dataSource:focus {
  background: #e6e6e6;
  outline: none;
}

.columnHeader {
  font-family: 'BentonSans Regular', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  margin: 0;
  display: grid;
  grid-template-columns: min-content 1fr;
  height: 21px;
  overflow: visible;
  text-overflow: ellipsis;
  position: relative;
  left: 8px;
}

.dropdownTrigger {
  background: transparent;
  border: 1px solid transparent;
  border-bottom: 1px solid #cbcbcb;
  cursor: pointer;
  display: inline-block;
  font-family: 'BentonSans Regular', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  margin: -2 px 0 0 0;
  height: 24px;
  overflow: hidden;
  padding: 0 24px 0 4px;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 236px;
  color: #666;
  text-align: left;
}

.dropdownTrigger:focus {
  outline: none;
  box-shadow: 0 0 4px #3498db;
  border: 1px solid #3498db;
}

.dataSource span {
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
}

.dropdownTrigger .dbIcon {
  position: relative;
  top: 2px;
}

.dataSource svg {
  position: absolute;
  left: 2px;
  top: 5px;
}

.downArrow {
  position: absolute;
  right: 10px;
  top: 4px;
}

.spacer {
  display: block;
  height: 8;
  border-bottom: 1px solid #e6e6e6;
  content: ' ';
}

.addDataSource {
  margin-top: 8px;
}
