.alert {
  border-left: 4px solid #2a79af;
  color: #333;
  padding: 4px;
  background-color: #eef5f9;
  margin: 12px 0;
  width: calc(100% - 12px);
  line-height: 18px;
  font-size: 12px;
  border-radius: 2px;
  text-indent: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}

.alert svg {
  color: #2a79af;
  position: absolute;
  display: block;
  top: 6px;
  left: 0;
  width: 20px;
}

.alert div {
  margin-left: 14px;
}
