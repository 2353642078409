@font-face {
  font-family: 'Benton Sans Book';
  src: url('Assets/fonts/BentonSans-Book.woff') format('font-woff');
  src:
    url('https://www.tableau.com/sites/all/fonts/bentonsans-book.eot#iefix') format('embedded-opentype'),
    url('https://www.tableau.com/sites/all/fonts/bentonsans-book.woff2') format('woff2'),
    url('https://www.tableau.com/sites/all/fonts/bentonsans-book.woff') format('woff');

  /* https://web.dev/font-display/?utm_source=lighthouse&utm_medium=devtools#how-to-avoid-showing-invisible-text */
  font-display: swap;
}

@font-face {
  font-family: 'Benton Sans Light';
  src: url('Assets/fonts/BentonSans-Light.woff') format('font-woff');
  src:
    url('https://www.tableau.com/sites/all/fonts/bentonsans-light.eot#iefix') format('embedded-­opentype'),
    url('https://www.tableau.com/sites/all/fonts/bentonsans-light.woff2') format('woff2'),
    url('https://www.tableau.com/sites/all/fonts/bentonsans-light.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Benton Sans Medium';
  src: url('Assets/fonts/BentonSans-Medium.woff') format('font-woff');
  src:
    url('https://www.tableau.com/sites/all/fonts/bentonsans-medium.eot#iefix') format('embedded-­opentype'),
    url('https://www.tableau.com/sites/all/fonts/bentonsans-medium.woff2') format('woff2'),
    url('https://www.tableau.com/sites/all/fonts/bentonsans-medium.woff') format('woff');
  font-display: swap;
}

html {
  font-family: 'BentonSans Book', sans-serif;
  color: #333;
}

body {
  margin: 0;
}

#root {
  height: 100vh;
}
