.testHarness {
  display: grid;
  margin: 10px;
}

button {
  display: block;
}

.datasourcer {
  border: 1px solid #aaa;
}

iframe {
  box-sizing: content-box;
}

.harnessActions {
  display: flex;
  margin: 5px 0;
  gap: 8px;
}

.outer {
  display: flex;
}

.stateDisplay {
  white-space: pre;
  border: 1px inset #aaa;
  width: 600;
  height: 50%;
  overflow-y: scroll;
}
