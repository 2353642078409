.dataSourceFieldPicker,
.dataSourceFieldHeader {
  position: relative;
  display: flex;
  white-space: nowrap;
  flex-direction: column;
}

/* todo: update with specific classes in css module refac */

.dataSourceFieldPicker input,
.dataSourceFieldPicker > button,
.filterTerm {
  appearance: none;
  width: 100%;
  position: relative;
  padding: 6px 12px 6px;
  background: #fff;
  border: 1px solid #cbcbcb;
  border-radius: 2px;
  font-family: 'BentonSans Book', sans-serif;
  font-style: normal;
  font-weight: 350;
  color: #666;
  text-align: left;
  font-size: 12px;
  max-height: 32px;
  line-height: 18px;
}

.dataSourceFieldPicker button.mapped {
  padding: 6px 12px 6px 34px;
}

.dataSourceFieldPicker > button {
  display: block;
  cursor: text;
}

.dataSourceFieldPicker > button:focus,
.dataSourceFieldPicker input {
  box-shadow: 0 0 4px #3498db;
  border: 1px solid #3498db;
  outline: none;
}

.filterTerm {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  display: none;
}

.open .filterTerm {
  display: block;
}

.dataSourceFieldPicker input:focus {
  box-shadow: 0 0 4px #3498db;
  border: 1px solid #3498db;
  outline: none;
}

.dataSourceFieldPicker button.dropdownButton:hover {
  border-color: #333;
  color: #333;
}

.invalid .dataSourceFieldPicker input,
.invalid .dataSourceFieldPicker input:focus,
.invalid .dataSourceFieldPicker button.dropdownButton {
  border: 1px solid #c93a47;
}

.invalid .dataSourceFieldPicker input:focus {
  outline: none;
  box-shadow: 0 0 4px #c93a47;
}

.dataSourceFieldPicker.open > button {
  display: none;
}

.dropdownButton {
  cursor: pointer;
}

.dropdownButton svg {
  right: 12px;
}

.dataSourceFieldPicker.open::before {
  content: none;
}

.fieldList {
  position: absolute;
  width: 100%;
  text-align: left;
  border: 1px solid #d4d4d4;
  background: white;
  z-index: 5;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  left: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  top: 32px;
  box-shadow: 0 1px 4px 0 #3333334d;
  box-sizing: border-box;
  display: none;
  flex-direction: column;
}

.open .fieldList {
  display: flex;
}

.zone1,
.zone2 {
  max-height: 240px;
}

.zone3 {
  max-height: 180px;
}

.zone4 {
  bottom: 100%;
  top: auto;
  max-height: 160px;
}

.zone5 {
  bottom: 100%;
  top: auto;
  max-height: 212px;
}

.zone6 {
  bottom: 100%;
  top: auto;
  max-height: 242px;
}

.fieldList.showAbovel {
  bottom: 100%;
  top: auto;
}

.hiddenFilter {
  display: none !important;
}

.disclosure {
  position: absolute;
  top: 50%;
  margin-top: -7px;
  right: 13px;
  color: #666;
}

.mappingError {
  width: 100%;
  text-align: left;
  color: #c93a47;
  font-size: 12px;
  font-family: 'Benton Sans Book', sans-serif;
  margin-top: 4px;
  margin-bottom: -8px;
}

.dataSourceField.empty {
  appearance: none;
  color: #bfbfbf;
  text-align: center;
  padding: 12px 0;
  background-color: inherit;
  border: none;
}

.dataSourceField.empty:hover {
  appearance: none;
  background-color: inherit;
  cursor: default;
}

.dataSourceFieldPicker button.clearFilter {
  cursor: pointer;
}

.dataSourceFieldPicker button.clearFilter:hover {
  color: #333;
}

.clearField,
.clearField:focus {
  position: absolute;
  width: 18px;
  height: 18px;
  right: 34px;
  top: 8px;
  cursor: pointer;
  outline: none;
  background: transparent;
  padding: 3px;
  border: none;
  display: block;
  color: #666;
}

.clearField:hover {
  background: #ebebeb;
}

.clearField:focus {
  box-shadow: 0 0 4px #3498db;
  border: 1px solid #3498db;
  padding: 2px;
}

.clearField:active {
  background: #ccc;
  box-shadow: none;
  border: 1px solid transparent;
}

.clearField span {
  visibility: hidden;
  max-height: 0;
  max-width: 0;
  cursor: default;
}

.dataType {
  left: 12px;
  display: block;
  position: absolute;
  top: 8px;
  height: 14px;
  color: #666;
}
