.toolTipText {
  width: 250px !important;
  padding: 8px 0;
  line-height: 18px;
  font-size: 12px;
  min-width: 280px;
}

.toolTipText * {
  padding-left: 4px;
}

.text {
  padding: 0 8px;
}

.fieldName {
  padding: 0;
  font-size: 16px;
  margin: 0;
}

.header {
  margin: 16px 0 4px 0;
  font-family: 'BentonSans Medium', sans-serif;
  padding: 0;
}

.typeHeader {
  margin-top: 16px;
}

.dataType svg {
  display: inline-block;
  position: relative;
  top: 3px;
  color: #666;
  padding: 0;
  margin: 0 4px 0 8px;
}

.sampleValues {
  margin: 0;
  padding: 0 8px 0 12px;
}

.sampleValue {
  padding: 0;
  margin: 0;
}
