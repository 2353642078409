@import url('./globalStyles.css');

* {
  box-sizing: border-box;
}

.data-mapper {
  text-align: center;
}

.data-mapper * {
  box-sizing: border-box;
}

.data-mapper-header {
  background-color: #282c34;
  min-height: 99vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.data-mapper-link {
  color: #61dafb;
}

li {
  list-style: none;
}

* button:disabled {
  cursor: not-allowed;
}

.focusTrap {
  height: 0;
  max-height: 0;
  width: 0;
  overflow: hidden;
  appearance: none;
  border: none;
  outline: none;
  background-color: transparent;
  color: transparent;
  padding: 0;
}
