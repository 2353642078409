.acceleratorField {
  background: #fafafa;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative; /* for positioning of icons */
  appearance: none;
  border: 1px solid #d4d4d4;
  padding: 6px 12px 6px 34px;
  margin: 0;
  color: #666;
  font-size: 12px;
  line-height: 18px;
  box-sizing: border-box;
  border-radius: 2px;
  max-height: 32px;
}

.fieldContainer {
  flex-grow: 1;
}

.fieldContainer:focus {
  outline: none;
}

.acceleratorField:hover {
  background: #ebebeb;
  border-color: #333;
  color: #333;
}

.fieldContainer:focus .acceleratorField {
  box-shadow: 0 0 4px #3498db;
  border: 1px solid #3498db;
  outline: none;
}

/* todo: make conditional on showDropDowns with conditional classes after css module refactor */

.acceleratorFieldNeedsData {
  width: calc(100% - 18px);
  display: block;
  margin-right: 0;
}

.field > svg {
  display: block;
  position: absolute;
  top: 8px;
  height: 14px;
  left: 12px;
  color: #666;
}

.field:hover svg {
  color: #333;
}
