.dataSourceField {
  appearance: none;
  background: white;
  display: inline-block;
  border: none;
  border-radius: 2px;
  width: 262px;
  max-width: 262px;
  font-size: 12px;
  padding: 0 8px;
  text-align: left;
  position: relative;
  cursor: pointer;
  color: #666;
  font-weight: 350;
  line-height: 18px;
}

.dataSourceField svg {
  left: 16px;
  display: block;
  position: absolute;
  top: 8px;
  height: 14px;
  color: #666;
}

.ineligible svg {
  color: #bfbfbf;
}

.ineligible {
  background: inherit;
  cursor: not-allowed;
  color: #bfbfbf;
}

.dataSourceField.highlighted {
  background: #ebebeb;
}

.dataSourceField.tableName {
  font-family: 'Benton Sans Medium', sans-serif;
  background: inherit;
  position: sticky;
  top: 0;
  z-index: 4;
  padding: 12px 12px 4px 12px;
  cursor: default;
}

.dataSourceField div {
  padding: 7px 12px 7px 38px;
  border-radius: 2px;
  position: relative;
}

.field:hover div {
  background-color: #f5f5f5;
}

.ineligible:hover div {
  background-color: inherit;
}

.dataSourceField.tableName:hover {
  background: inherit;
}
