.fieldGrid {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: minmax(0, 1fr) 14px minmax(0, 1fr);
  width: 100%;
  column-gap: 14px;
  row-gap: 24px;
  align-items: center;
  padding: 0 16px;
}

.mapper {
  display: flex;
  flex-direction: column;
  height: 99vh;
}

.preHeader {
  font-family: 'BentonSans Light', sans-serif;
  padding-right: 4px;
  font-weight: 300;
}

.columnHeader {
  font-family: 'BentonSans Regular', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  margin: 0;
}

.v2ColumnHeader {
  cursor: pointer;
}

.columnHeader svg {
  position: relative;
  top: 2px;
}

.unmappedFieldCount {
  padding-left: 8px;
  color: #666;
  font-size: 12px;
  flex-grow: 1;
}

.footer {
  text-align: left;
  padding: 16px;
  display: flex;
  align-items: center;
  columns: 2;
  background: white;
}

.fieldRowHeader {
  background: white;

  /* 30px is balancing out the width of the scrollbar */
  padding: 24px 30px 16px 16px;
}

.scrollContainer {
  flex: 1;
}

@property --pgPercentage {
  syntax: '<number>';
  inherits: false;
  initial-value: 0;
}

.progressBar {
  --fg: #008057;
  --bg: #ebebeb;
  --pg-percentage: var(--value);

  width: 24px;
  height: 24px;
  border-radius: 50%;
  background:
    radial-gradient(closest-side, white 70%, transparent 0 99.9%, white 0),
    conic-gradient(var(--fg) calc(var(--pg-percentage) * 1%), var(--bg) 0);
}

.status {
  fill: #bfbfbf;
  color: #bfbfbf;
}

.iconSelector {
  flex-grow: 1;
}

.iconSelector:focus-within .status {
  color: #666;
  fill: #666;
}
