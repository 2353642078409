.overlay {
  position: fixed;
  width: 100vw;
  height: 99vh;
  top: 0;
  left: 0;
  background: rgb(255 255 255 / 90%);
  backdrop-filter: blur(6px);
  z-index: 6;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  padding-inline: 24px;
}

.overlayHeader {
  font-size: 20px;
  font-weight: 350;
  line-height: 24px;
  text-align: center;
  width: 320px;
  height: 24px;
  margin: 0;
}

.overlayText {
  font-size: 13px;
  font-weight: 350;
  line-height: 18px;
  text-align: center;
  color: #666;
  margin: 0 10px 20px 10px;
}

.overlayEmptyText {
  height: 0;
  margin-bottom: 0;
}

.overlayFooter {
  display: flex;
  gap: 30px;
}

.overlayFooter > * {
  flex-grow: 1;
  min-width: 124px;
}
