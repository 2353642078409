.Button {
  cursor: pointer;
  font-family: 'BentonSans Medium', sans-serif;
  font-weight: 500;
  font-size: 13px;
  padding: 5.5px 24px;
  border-radius: 2px;
  color: #fff;
  background: #1a699e;
  border: 1px solid transparent;
}

.Button:hover {
  background: #2a79af;
}

.Button:active {
  background: #15557f;
}

.Button:focus {
  box-shadow: 0 0 4px #3498db;
  border-color: #3498db;
  outline: none;
}

.secondary {
  color: #666;
  background: #fff;
  border-color: #666;
}

.secondary:hover {
  background: #ebebeb;
}

.secondary:active {
  background: #cbcbcb;
}

.deemphasized {
  background: none;
  color: #1a699e;
  border: 1px solid transparent;
  cursor: pointer;
  outline: inherit;
  font-size: 14px;
  line-height: 13px;
  letter-spacing: 0.2px;
  padding: 5.5px;
}

.deemphasized:focus {
  box-shadow: 0 0 4px #3498db;
  border-color: #3498db;
}

.deemphasized:hover {
  color: #2a79af;
  background: #ebebeb;
}

.deemphasized:active {
  color: #15557f;
  border-color: transparent;
  box-shadow: none;
  background: #ccc;
}

/* order of the following rules is important for cascade reasons */

.Button:disabled {
  background: #bfbfbf;
  cursor: not-allowed;
}

.deemphasized:disabled {
  color: #bfbfbf;
  background: transparent;
}

.deemphasized:disabled:hover {
  background: transparent;
}
