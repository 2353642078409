.dataSourcePicker {
  --inner-width: 244px;

  font-size: 12px;
  padding: 14px 32px;
  margin: 7px 0 7px -16px;
  width: 308px;
}

.dataSourcePicker p,
.dataSourcePicker h2 {
  width: var(--inner-width);
  margin: 0 auto;
}

.dataSourcePicker h2 {
  margin: 4px auto 16px;
  font-size: 14px;
}

.dataSourcePicker p {
  margin-bottom: 24px;
  line-height: 18px;
}

.preHeader {
  font-family: 'BentonSans Light', sans-serif;
  padding-right: 4px;
  font-weight: 300;
}

.columnHeader {
  font-family: 'BentonSans Regular', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  margin: 0;
}

.dataSource {
  cursor: pointer;
  height: 20px;
  line-height: 20px;
  padding: 0 4px;
  margin-bottom: 8px;
  border: none;
  border-radius: 2px;
  background: transparent;
  max-width: 100%;
  width: 100%;
  font-weight: normal;
  font-family: 'Benton Sans Book', sans-serif;
  font-size: 12px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dataSource:hover {
  background: #f5f5f5;
}

.sourceListWrap {
  width: var(--inner-width);
  margin: 0;
  border: 1px solid #e1e1e1;
  font-family: 'Benton Sans Book', sans-serif;
  padding: 15px;
  overflow: hidden;
  height: 195px;
  border-radius: 2px;
}

.sourceListHeader {
  font-size: 12px;
  font-weight: 500;
  font-family: 'Benton Sans Medium', sans-serif;
  line-height: 14.4px;
  padding: 0 4px;
  margin: 0 0 14px;
}

.sourceList {
  overflow-y: auto;
  max-height: 92%;
  overflow-x: hidden;
  width: calc(var(inner-width) - 20);
}

.addDataSource {
  margin: 24px auto;
  width: var(--inner-width);
}

.addDataSource svg {
  position: relative;
  top: 4px;
}

.dataSource span {
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
}

.dataSource:focus {
  box-shadow: 0 0 4px #3498db;
  border: 1px solid #3498db;
  outline: none;
}
