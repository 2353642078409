.ScrollContainer {
  overflow-y: scroll;
  overflow-x: hidden;
  overflow-anchor: none;
  position: relative; /* for magic shadow */
  height: 100%;
}

.wrap {
  position: relative;
  overflow-y: hidden;
  height: 100%;
}

.atTop::before,
.atBottom::after {
  display: none;
}

.wrap::before {
  z-index: 1;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 8px;
  background: linear-gradient(to top, #0000, #0002);
}

.wrap::after {
  z-index: 1;
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 8px;
  background: linear-gradient(to bottom, #0000, #0002);
}
