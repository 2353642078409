.container {
  padding: 16px 16px 22px;
  letter-spacing: 0.2px;
  font-weight: 350;
  display: flex;
  flex-direction: column;
  height: 99vh;
}

.header {
  text-align: left;
  font-size: 18px;
  line-height: 32px;
  font-weight: 350;
  margin: 0;
}

.text {
  font-size: 14px;
  line-height: 21px;
  margin: 4px 0 0;
}

.img {
  display: block;
  width: 100%;
  box-sizing: border-box;
  border-radius: 2px;
}

.buttonContainer {
  display: flex;
  gap: 18px;
  padding-top: 16px;
  justify-content: flex-end;
  margin-right: -4px; /* makes text of the deemphasized button align with right padding */
}

.container header,
.buttonContainer {
  flex-shrink: 0;
}

.textContainer {
  flex-grow: 1;
  margin-top: 24px;
  overflow-y: auto;
}
